import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import "react-vertical-timeline-component/style.min.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import LoginForm from "../components/loginForm"
import { useDispatch, useSelector } from "react-redux"
import { ButtonStyled } from "../components/styles/styles"
import {
  fetchResourceFromFolder,
  uploadFile,
  uploadFileGetUrl,
} from "../redux/firebaseAction"

const ResourcesPage = () => {
  const { admin, email, fetching, educatorsList, resourcesList } = useSelector(
    state => ({
      ...state.mainReducer.user.user,
      ...state.mainReducer,
    })
  )
  return (
    <>
      {email ? (
        <RenderList
          fetching={fetching}
          resourcesList={resourcesList}
          educatorsList={educatorsList}
        />
      ) : (
        <Login />
      )}
    </>
  )
}

export default ResourcesPage

const RenderList = ({ resourcesList }) => {
  const { fetching } = useSelector(state => ({
    ...state.mainReducer,
  }))
  if (!fetching) {
    return (
      <>
        <Layout>
          <div>
            <ResourceSections title="Admin" resourcesList={resourcesList} />
            <ResourceSections title="Maestro" resourcesList={resourcesList} />
            <ResourceSections title="Tools" resourcesList={resourcesList} />
          </div>
        </Layout>
      </>
    )
  }
  return <Layout>Loading...</Layout>
}

const Login = () => {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  )
}

const ResourceSections = ({ title, resourcesList }) => {
  const { admin, fetching, resourcesUrl } = useSelector(state => ({
    ...state.mainReducer,
  }))
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(() => fetchResourceFromFolder(title))
  }, [])
  const getURLs = resourcesUrl.filter(item => item.section === title)

  if (!fetching) {
    return (
      <CustomDiv>
        <table>
          <tbody>
            <CustomTr>
              <th>{title}</th>
              <th></th>
            </CustomTr>
            {getURLs.map((document, index) => (
              <tr key={index}>
                <td>{document.filename}</td>
                <td>
                  <a href={document.url}>Download</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {admin && <UploadResource title={title} />}
      </CustomDiv>
    )
  }
  return <>Loading...</>
}

const UploadResource = props => {
  const { email } = useSelector(state => ({
    ...state.mainReducer.user.user,
  }))
  const folderName = props.title
  const uploadFile2ResourcesList = () =>
    dispatch(uploadFileGetUrl(file, folderName))
  const dispatch = useDispatch()
  const upload2Firebase = file => dispatch(uploadFile(file, folderName))
  const [file, setFile] = useState()
  const handleFile = e => {
    setFile(e.target.files[0])
  }
  const handleUpload = () => {
    if (file) {
      uploadFile2ResourcesList(file, folderName)
      // upload2Firebase(file, folderName)
      // navigate('/')
    }
  }
  return (
    <CustomUpload>
      <input type="file" id={props.title} onChange={e => handleFile(e)} />
      <ButtonStyled text="white" bg="black" onClick={() => handleUpload()}>
        Upload
      </ButtonStyled>
    </CustomUpload>
  )
}

//styled-components
const CustomDiv = styled.div`
  margin: 10px 10px 40px 10px;
  // height: 100vh;
  // border: 1px solid grey;
  span {
    margin: 30px;
  }
  .rbc-event {
    background-color: black;
  }
`
const CustomUpload = styled.div`
  text-align: end;
  button {
  }
  span {
    margin: 0;
  }
  label {
    margin: 0;
  }
  #Admin,#Maestro,#Tools{
    font-size: 14px;
  }
  input {
  }
`

const CustomTr = styled.tr`
  background-color: black;
  color: white;
  padding: 10px;
  span {
    margin: 30px;
  }
`
